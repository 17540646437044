import { Box, Link, Stack } from '@packages/shared';
import type { MenuItem, MenuType } from '@packages/cms-components/interfaces';
import { useAssignLinkTargetBasedOnBotStatus } from '../../hooks/useAssignLinkTargetBasedOnBotStatus';

const filterAndMarkActiveMenu = (menuData: MenuItem[], currentPath: string): MenuType => {
  menuData.forEach((menuEntry) => {
    if (menuEntry.children) {
      const { active: remove, ...menuEntryChildren } = menuEntry.children;
      // eslint-disable-next-line no-param-reassign
      menuEntry.children = filterAndMarkActiveMenu(Object.values(menuEntryChildren), currentPath);
    }
    // eslint-disable-next-line no-param-reassign
    menuEntry.active =
      menuEntry.children?.active ||
      (menuEntry.is_external === false && menuEntry.alias === currentPath);
  });
  const active = Object.values(menuData).some(
    (menuChild) =>
      menuChild.children?.active ||
      (menuChild.is_external === false && menuChild.alias === currentPath),
  );
  return { active, ...menuData };
};

const RenderMenuChildren = ({ entry }: { entry: MenuItem }): JSX.Element => {
  // eslint-disable-next-line no-nested-ternary
  const beforeIcon = Object.hasOwn(entry, 'children') ? (entry.active ? '-' : '+') : '';
  const href = entry.is_external ? entry.uri : entry.alias;
  const assignLinkTarget = useAssignLinkTargetBasedOnBotStatus();
  const finalLink = assignLinkTarget(href);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      key={entry.title}
    >
      <Box sx={{ width: (theme) => theme.spacing(1), mr: 1 }}>{beforeIcon}</Box>
      <Box
        sx={{
          ':has(> a:hover)': {
            backgroundColor: 'grey.light',
          },
        }}
      >
        <Link
          sx={{
            color: 'text.dark',
            textDecoration: 'none',
            fontWeight: entry.active ? 'bold' : 'normal',
            padding: '0.5rem 1rem 0.5rem 0rem',
          }}
          href={finalLink}
        >
          {entry.title}
        </Link>
        {entry.active && entry.children && (
          <Stack spacing={1} sx={{ marginBlock: 1 }}>
            {Object.values(entry.children)
              .filter((child) => typeof child !== 'boolean')
              .map((child, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <RenderMenuChildren entry={child as MenuItem} key={index} />
              ))}
          </Stack>
        )}
      </Box>
    </Box>
  );
};

const RenderMenu = ({ menuData, currentPath }: { menuData: MenuType; currentPath: string }) => {
  const { active: remove, ...menuChildren } = menuData;
  const { active: removeFinal, ...finalMenu } = filterAndMarkActiveMenu(
    Object.values(menuChildren),
    currentPath,
  );
  return (
    <>
      {Object.values(finalMenu).map((entry, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <RenderMenuChildren entry={entry} key={index} />
      ))}
    </>
  );
};

export const ContentWithMenu = ({
  menuData,
  currentPath,
  children,
}: {
  menuData: MenuType;
  currentPath: string;
  children: JSX.Element;
}) => (
  <Box sx={{ display: 'flex', flexDirection: ['column-reverse', null, 'row'] }}>
    <Stack sx={{ mr: 4, typography: 'body2', width: ['100%', '100%', '20%'] }} spacing={1}>
      <RenderMenu menuData={menuData} currentPath={currentPath} />
    </Stack>
    <Box sx={{ width: ['100%', '100%', '78%'] }}>{children}</Box>
  </Box>
);
