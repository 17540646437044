import type { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';
import { ErrorBoundary, HeadMeta, cached, Box, KimRating } from '@packages/shared';
import type { Language } from '@packages/config';
import {
  cmsFetcher,
  UrlType,
  SeoContent,
  fetcherSeoContent,
  getMainAudience,
  Survey,
  ChatBot,
} from '@packages/cms-components';
import { Content } from '@packages/cms-components/src/components/CmsContent';
import config, { getFullLocale } from '@packages/config';
import type { CmsData, MenuType } from '@packages/cms-components/interfaces';
import { metaInfoOrganization } from '@packages/cms-components/src/utils/metaInfoOrganization';
import { getEcState, logger } from '@packages/utilities';
import { ScrollTopButton, PageMetaData } from '@packages/modules';
import { localizedPathnameCacheKey } from '@packages/config/src/default';
import dynamic from 'next/dynamic';
import { useTracking } from '@packages/tracking';
import { Suspense, useEffect } from 'react';
import { sendAdditionalSubscribe } from '@packages/cms-components/src/components/Forms/NewsletterSubscribeForm/helpers/sendAdditionalSubscribe';
import { usePathname } from 'next/navigation';
import { promotionBannerDataFetcher } from '@packages/cms-components/src/modules/PromotionBanner/utils/fetch/promotionBannerDataFetcher';
import { showNewsletterSheet } from '../../../util/showNewsletterSheet';
import Custom404 from '../../404';

const NewsletterSheet = dynamic(
  () =>
    import(
      /* webpackChunkName: 'CMS_NewsletterSheet' */ '@packages/cms-components/src/components/Forms/NewsletterSheet/NewsletterSheet'
    ),
  { loading: () => <span data-testid="newslettersheet" />, ssr: false },
);

// https://nextjs.org/docs/api-reference/data-fetching/get-static-props#getstaticprops-with-typescript
type StaticContentProps = InferGetServerSidePropsType<typeof getServerSideProps>;

const StaticContent: NextPage<StaticContentProps> = (props) => {
  const dispatchGTMEvent = useTracking();
  const pathname = usePathname();
  const { data, menuData, seoContent, htmlHeadInfo, metaInfoOrganizationData, token, referer } =
    props;
  useEffect(() => {
    if (data?.field_pagegroup) {
      // dispatch information to fire landmark: 'http://localhost/servlet/11?clientId=1009&landmark=page&ssid=1009xxxxxxxxx&pc=storefront&pc2=homepage'
      dispatchGTMEvent({
        event: 'ga_event',
        eventValue: data.field_pagegroup,
        eventAction: 'view',
        eventCategory: data.field_pagegroup,
        eventLabel: 'inspire',
      });
    }
  });

  if (!data) {
    return <Custom404 />;
  }

  const {
    alternateLinks,
    field_pagegroup: trackingType,
    field_page_group_tracking: trackingTemplate,
  } = data;

  const {
    metaTitle: title,
    metaDescription: description,
    metaRobots: robots,
    metaCanonical: canonical,
  } = htmlHeadInfo;

  return (
    <ErrorBoundary fallback={<div data-testid="static-content-error" />}>
      {/* needs to be first component in page render flow because of timing issues with glycerin tracking */}
      {trackingType && trackingTemplate && (
        <PageMetaData
          page={{
            type: trackingType.toLowerCase().replace(/ks_|sh_/, ''),
            template: trackingTemplate,
          }}
          /* use pathname || undefined here becaurse pathname from usePathname() 
          can be string | null while PageMetaData expects string | undefined */
          pathname={pathname || undefined}
        />
      )}
      <HeadMeta
        meta={{ title, description, robots }}
        links={{ alternateLinks, canonical }}
        schemaorg={metaInfoOrganizationData}
      />
      <Content data={data} menuData={menuData} token={token} />
      {trackingTemplate === 'Startseite' && (
        <ErrorBoundary>
          <KimRating pageType="storefront" templateParams={{ x17: referer ?? 'x' }} />
        </ErrorBoundary>
      )}
      {seoContent?.linkGroups && seoContent.linkGroups.length > 0 && (
        <SeoContent seoContent={seoContent} />
      )}
      <Box
        sx={{
          position: 'fixed',
          bottom: '4rem',
          right: '2%',
          zIndex: 'appBanner',
        }}
      >
        <ScrollTopButton />
      </Box>

      {showNewsletterSheet(data.paragraph) && (
        <ErrorBoundary fallback={<span data-testid="newslettersheet-error" />}>
          <Suspense fallback={<span data-testid="suspenseNlSheet" />}>
            <NewsletterSheet />
          </Suspense>
        </ErrorBoundary>
      )}

      <ErrorBoundary>
        <Survey />
      </ErrorBoundary>

      <ErrorBoundary>
        <ChatBot />
      </ErrorBoundary>
    </ErrorBoundary>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { params, query, req, res } = context;
  const { token, page, lang } = query;
  const language = typeof lang === 'string' ? lang : config.i18n.defaultLocale.split('-')[0];
  const tokenValue = Array.isArray(token) ? token[0] : token;
  const pageValue = Array.isArray(page) ? page[0] : page;
  if (
    pageValue === '3' &&
    tokenValue &&
    tokenValue.length > 0 &&
    config.forms.apiAgnitasUrl.values.sendAdditionalSubscribe
  ) {
    sendAdditionalSubscribe(tokenValue).catch((e) => {
      logger.error(e, 'error while sending request to MAPP');
    });
  }

  const cmsPage = params?.cmsPage;
  const pathname = Array.isArray(cmsPage) ? cmsPage.join('/') : cmsPage || '';

  // should avoid 404 calls against Drupal/Google Bucket
  if (cmsPage && /\.([^.]*?)(?=\?|#|$)/.test(cmsPage.slice(-1)[0])) {
    return { props: {} };
  }

  const locale = getFullLocale(language);
  const alternateLanguage = (language || locale.split('-')[0]) as Language;
  const targetLanguage: string = config.i18n.languages.find(
    (l: string) => l !== alternateLanguage,
  )!;
  const headerInfo = config.headerInformation[alternateLanguage];

  try {
    const [seoContentResult, cmsDataResult, promoData] = await Promise.allSettled([
      cached(
        `cmsPageSeoContent-${locale}`,
        async () => fetcherSeoContent(locale),
        1000 * 60 * 60,
      )(),
      cached(`cmsPageContent-${locale}-${pathname}`, async () =>
        cmsFetcher<CmsData>({
          bucketUrl: config.staticContent.apiEndpoints.bucket,
          pathname: pathname || '',
          locale,
        }),
      )(),
      promotionBannerDataFetcher(query, req.cookies, context, locale),
    ]);

    if (cmsDataResult.status === 'rejected') {
      throw new Error(cmsDataResult.reason);
    }

    const data = cmsDataResult.value;

    if (!data) {
      return { props: {} };
    }
    // The value from data.menu contains the type (e.g. "service", "shop") of the menu. This is used to fetch the correct json file.
    const menuData =
      data && 'menu' in data && data.menu !== ''
        ? await cmsFetcher<MenuType>({
            bucketUrl: config.staticContent.apiEndpoints.bucket,
            pathname: data.menu,
            locale,
            type: UrlType.MENU,
          })
        : undefined;

    const {
      field_seo_title: metaTitle = headerInfo?.title,
      field_seo_description: metaDescription = headerInfo?.description,
      field_seo_robots: metaRobots = headerInfo?.robots,
      alternateLinks,
    } = data;

    // filter and map separately to have a clean array of type
    // {
    //   alternativeLocale: string;
    //   alternativeLink: string;
    // }[]
    const alternateLinkTags =
      alternateLinks &&
      Object.keys(alternateLinks)
        .filter((alternativeLocale) => alternativeLocale !== language)
        .map((alternativeLocale) => ({
          alternativeLocale,
          alternativeLink: alternateLinks?.[alternativeLocale] || '',
        }));

    /**
     * The `buildLocalizedPathname` function get a localized URL.
     *
     * Example of alternateLinks:
     *    {
     *      "de": "https://cms.ackermannch.empirie.cloud/de/service-hilfe/ueber-uns/datenschutz",
     *      "fr": "https://cms.ackermannch.empirie.cloud/fr/aide-service/qui-sommes-nous/protection-des-donnees"
     *    }
     */
    const buildLocalizedPathname = () => {
      if (alternateLinks && targetLanguage in alternateLinks) {
        const url = new URL(alternateLinks[targetLanguage]);
        // return only the path of the URL, removing the protocol and host
        return url.pathname;
      }

      return null;
    };

    const localizedPathname = buildLocalizedPathname();

    if (promoData.status === 'rejected') {
      logger.error(
        { description: 'Promotion Banner Data Fetch (Cms-Page)' },
        'Could not fetch data',
      );
    }

    const seoContent = seoContentResult.status === 'fulfilled' ? seoContentResult.value : undefined;
    if (seoContentResult.status === 'rejected') {
      logger.error(seoContentResult.reason);
    }

    // https://nextjs.org/docs/pages/building-your-application/data-fetching/get-server-side-props#caching-with-server-side-rendering-ssr
    res.setHeader('Cache-Control', 'public, s-maxage=300, stale-while-revalidate=59');

    const audience = req.cookies ? getMainAudience(getEcState(req.cookies)) : 'anonymous';
    const promotionBannerCacheKey = `promotionBannerData-${language}-${audience}`;

    return {
      props: {
        data,
        ...(menuData && 'active' in menuData && { menuData }),
        ...(seoContent && { seoContent }),
        locale,
        alternateLinkTags,
        // organization meta data only on storefront
        ...(data.type === 'storefront_content' && {
          metaInfoOrganizationData: metaInfoOrganization(locale),
        }),
        htmlHeadInfo: {
          metaTitle,
          metaDescription,
          metaRobots,
          metaCanonical:
            headerInfo?.canonical && data.type !== 'storefront_content'
              ? // use the path to the page, unless it is storefront
                `${headerInfo?.canonical}${
                  alternateLanguage !== 'de' ? `/${alternateLanguage}` : ''
                }/${pathname}`
              : headerInfo?.canonical,
        },
        fallback: {
          [promotionBannerCacheKey]: promoData.status === 'fulfilled' ? promoData.value : null,
          ...(alternateLinks &&
            localizedPathname && {
              [localizedPathnameCacheKey]: localizedPathname,
            }),
        },
        token: tokenValue ?? null,
        ...(req?.headers?.referer && { referer: req.headers.referer }),
      },
    };
  } catch (error) {
    logger.error(error);
    return {
      props: {},
    };
  }
};

// eslint-disable-next-line import/no-default-export
export default StaticContent;
